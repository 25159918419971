import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConnectedService } from './connected.service';
import User from './models/user.model';
import Country from './models/country.model';

const FlagsURLs = {
	LOADUSERFLAGS: { endp: 'users/{userId}/flags', meth: 'GET', pub: false }, // userflags
	LOADGROUPFLAGS: { endp: 'groups/{groupId}/flags', meth: 'GET', pub: false }, // groupflags
};

export interface IFlagUser {
	country: Country;
	total: number;
	items: any[];
}

@Injectable({ providedIn: 'root' })
export class MiniFlagsService extends ConnectedService {

	constructor(
		private readonly httpConn: HttpClient,
	) {
		super();
	}

	getFlagsForUserOrGroup(selfSessId: string, userId?: string, groupId?: string): Observable<IFlagUser[]> {
		let isGroup = false;
		let meth: string, endpoint: string;
		const headers = new HttpHeaders().set('Authorization', `Bearer ${selfSessId}`);
		if (groupId) {
			isGroup = true;
			meth = FlagsURLs.LOADGROUPFLAGS.meth;
			endpoint = this.makeEndpoint(FlagsURLs.LOADGROUPFLAGS.endp, { groupId });
		} else if (userId) {
			meth = FlagsURLs.LOADUSERFLAGS.meth;
			endpoint = this.makeEndpoint(FlagsURLs.LOADUSERFLAGS.endp, { userId });
		}

		return this.httpConn.request<any[]>(meth, endpoint, { headers }).pipe(
			map(res => !Array.isArray(res) ? [] :
				res.reduce((result: IFlagUser[], item) => {
					// check if this country is already stored in results
					let flagPos = result.findIndex((elem) => elem.country.id === item.code);

					// if country code is not stored yet, create new flagoccurrence
					if (flagPos < 0) {
						// now it is stored, save position; it is given by result.push(item)-1
						flagPos = result.push({
							country: new Country(item.code),
							items: [], // item: { user, times, isSelfCountry }
							total: 0
						}) - 1;
					}

					const currentItem = result[flagPos];

					const { user, name, surname, username, selfcountry1, selfcountry2 } = item;
					const currentUser: User = Object.assign(new User(user), {
						name, surname, username,
						country1: new Country(selfcountry1),
						country2: selfcountry2 ? new Country(selfcountry2) : null
					});

					currentItem.total += item.times;
					currentItem.items.push({
						user: currentUser,
						times: item.times || null,
						isSelfCountry: (currentUser.country1.id === item.code
							|| currentUser.country2?.id === item.code)
					});

					return result;
				}, [])
				.sort((itemA, itemB) => isGroup
					? itemB.total - itemA.total
					: itemB.items[0].times - itemA.items[0].times
				)
			)
		);
	}
}
