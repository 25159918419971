<ng-container *ngIf="!loading; else isLoading">
	<countries-map *ngIf="!hasError; else mapHasError"
		[ngClass]="['map-component']"
		[data]="mapData"
		[apiKey]="apiKey"
		[showCaption]="false"
		[minValue]="minValue"
		[maxValue]="maxValue"
		[valueLabel]="valueLabel"
		[maxColor]="countryColor"
		[autoResize]="true"
		[backgroundColor]="themeBackgroundColor"
		[exceptionColor]="selfCountryColor"
		(chartSelect)="regionClicked($event)"
		(chartError)="errorLoadingMap($event)"
		(chartReady)="mapLoaded()">
	</countries-map>
	<ng-template #mapHasError>
		<div class="full-center">Error loading map!</div>
	</ng-template>
</ng-container>
<ng-template #isLoading>
	<div class="spinner-container full-center">
		<h4 style="font-weight:100;">Loading</h4>
	</div>
</ng-template>
