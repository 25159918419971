import { en as countriesEN } from '@jagomf/countrieslist';

export default class Country {

	id: string;

	constructor(idOrName?: string, city?: string) {
		this.id = idOrName.toLowerCase();
	}
}
