import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export abstract class ConnectedService {

	protected makeEndpoint(opEndpoint: string, urlParts?: object): string {
		const endpointPath = urlParts ? Object.entries(urlParts).reduce((acc, [currKey, currVal], i) =>
			acc + (i ? '/' : '') + opEndpoint.replace(`{${currKey}}`, currVal)
		, '') : opEndpoint;
		return `${environment.API.endpoint}${endpointPath}`;
	}
}
