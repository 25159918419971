import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CountriesMapModule } from 'countries-map';
import { AppComponent } from './app.component';
import { MiniFlagsService } from './mini-flags.service';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		HttpClientModule,
		RouterModule.forRoot([]),
		CountriesMapModule,
		BrowserModule
	],
	providers: [MiniFlagsService],
	bootstrap: [AppComponent]
})
export class AppModule { }
