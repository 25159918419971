import Country from './country.model';

export default class User {
	readonly id: string;
	username: string;
	country1: Country;
	country2: Country = null;

	constructor(userId?: string) {
		if (userId) {
			this.id = userId.toString();
		}
	}

}
