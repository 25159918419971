import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { skip } from 'rxjs/operators';
import { ChartSelectEvent, CountriesData, CountryExtraData } from 'countries-map';
import { KEYS } from './app.const';
import Country from './models/country.model';
import User from './models/user.model';
import { IFlagUser, MiniFlagsService } from './mini-flags.service';

type NativeComms = { postMessage: (val: string) => void };
declare var CommLoaded: NativeComms;
declare var CommCountry: NativeComms;
enum CommLoadedState { loaded = 'loaded', error = 'error' }

@Component({
	selector: 'dflgr-map',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	constructor(
		private readonly route: ActivatedRoute,
		private readonly cdRef: ChangeDetectorRef,
		private readonly flagsProvider: MiniFlagsService
	) { }

	selfSessId: string;
	userId: string;
	groupId: string;
	darkTheme = false;
	list: IFlagUser[];
	loading = true;
	hasError = false;

	// Data for CountriesMap
	mapData: CountriesData;

	// Map config
	readonly apiKey = KEYS.geoCharts;
	readonly valueLabel = this.isGroup ? 'Total' : 'Flags';
	readonly minValue = 0;
	readonly maxValue = 1;
	readonly countryColor = '#4dd0e1';
	readonly selfCountryColor = '#ffee58';
	readonly backgroundColor = '#303030';

	get isGroup() {
		return this.groupId && !this.userId;
	}

	@HostBinding('style.background-color')
	get themeBackgroundColor() {
		return this.darkTheme ? this.backgroundColor : 'white';
	}

	/**
	 * Pasar de un array IFlagUser[] a un objeto CountriesData
	 */
	private generateMap() {
		const isGroup = this.isGroup;
		this.mapData = this.list.reduce((result: CountriesData, flagUser: IFlagUser) => {
			const newCountry: CountriesData = {
				[flagUser.country.id.toUpperCase()]: {
					value: flagUser.items.some(user => user.isSelfCountry) ?
						null : flagUser.total,
					extra: isGroup ?
						flagUser.items.reduce((acc: CountryExtraData, userFlag: { isSelfCountry: boolean, times: number, user: User }) =>
							userFlag.isSelfCountry ? acc : Object.assign(acc, {
								[userFlag.user.username]: userFlag.times
							})
						, {})
						: undefined
				}
			};
			return Object.assign(result, newCountry);
		}, {});
	}

	mapLoaded() {
		this.loading = false;
		this.hasError = false;
		this.sendNativeLoadedMsg(CommLoadedState.loaded);
	}

	/**
	 * Native communications
	 */
	private sendNativeLoadedMsg(msg: string) {
		if (typeof CommLoaded === 'undefined') { return; } // necessary for debugging when declared vars are not initialized
		this.sendNativeMsg(CommLoaded, msg);
	}

	/**
	 * Native communications
	 */
	private sendNativeMsg(communicator: NativeComms, msg: string) {
		communicator?.postMessage(msg);
	}

	/**
	 * Native communications
	 */
	private sendNativeCountryMsg(msg: string) {
		if (typeof CommCountry === 'undefined') { return; } // necessary for debugging when declared vars are not initialized
		this.sendNativeMsg(CommCountry, msg);
	}

	regionClicked(ev: ChartSelectEvent) {
		const selectedCountry = ev.selected ? new Country(ev.country.toLowerCase()) : null;
		this.sendNativeCountryMsg(selectedCountry?.id ?? 'null');
	}

	ngOnInit() {
		// Skip first emission of queryParams because it is a BehaviorSubject and params are initially empty
		this.route.queryParams.pipe(skip(1)).subscribe(params => {
			this.userId = params.userId;
			this.groupId = params.groupId;
			this.selfSessId = params.selfSessId;
			this.darkTheme = params.dark;

			if (this.selfSessId && (this.userId || this.groupId)) {
				this.flagsProvider.getFlagsForUserOrGroup(this.selfSessId, this.userId, this.groupId)
					.subscribe({ next: this.onRetrievedData.bind(this), error: this.errorLoadingMap.bind(this)});
			} else {
				this.errorLoadingMap();
			}
		});
	}

	private onRetrievedData(data: IFlagUser[]) {
		this.list = data;
		this.generateMap();
		this.mapLoaded();
		this.cdRef.detectChanges();
	}

	errorLoadingMap(err?) {
		this.loading = false;
		this.hasError = true;
		this.cdRef.detectChanges();
		this.sendNativeLoadedMsg(CommLoadedState.error);
	}
}
